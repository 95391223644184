import React from 'react';
import logo from './logo.svg';
import './App.css';
import './components/LogoType.js'
import LogoType from './components/LogoType.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <LogoType showSubhead="true" />
        <div className="App-footer">
          myEmber® is a registered service mark of Syzygy Softwerks LLC. All rights reserved.
        </div>
      </header>
      <footer className="App-footer">
      </footer>
    </div>
  );
}

export default App;
