import React from 'react';
import '../App.css';

function LogoType(props) {
  return (
    <div>
      <div className="LogoType">
        <span className="LogoType">myEmber</span>
      </div>
      { props.showSubhead &&
        <div className="LogoTypeSub">
          spark change
        </div>
      }
    </div>
  );
}

export default LogoType;